<template>
  <!-- 删除 -->
  <el-dialog
    :title="$t('label.file.delete.info')"
    :visible.sync="isShowDialog"
    width="30%"
    append-to-body
  >
    <!--确认删除-->
    <span
      >{{ $t("label.sure.delete") }}
      <span style="color: dodgerblue"
        >{{ showData.name + "." + showData.type }}？</span
      ></span
    >
    <span slot="footer" class="dialog-footer">
      <!--        取消-->
      <el-button @click="isShowDialog = false" :loading="deleteLoading">{{
        $t("label.cancel")
      }}</el-button>
      <!--        确定-->
      <el-button
        type="primary"
        @click="confirmDelete"
        :loading="deleteLoading"
        >{{ $t("label.confirm") }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import * as api from "./api.js";
export default {
  props: {
    dialogDelete: {
      type: Boolean,
      default: false,
    },
    showData: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: "",
    },
  },
  computed: {
    isShowDialog: {
      get() {
        return this.dialogDelete;
      },
      set() {
        this.$emit("update:dialogDelete", false);
      },
    },
  },
  data() {
    return {
      deleteLoading: false, // 删除弹窗loading
    };
  },
  methods: {
    /**
     * 删除文件
     */
    confirmDelete() {
      this.deleteLoading = true;
      api
        .deleteFile({
          id: this.id,
        })
        .then(() => {
          this.isShowDialog = false;
          this.deleteLoading = false;
          // 文件删除成功 label.file.delete.success
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("label.file.delete.success"),
          });
          // 删除文件成功，讲事件发送给父组件调用刷新列表使用
          this.$emit("deleteSuccess");
        });
    },
  },
};
</script>

<style>
</style>